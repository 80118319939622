import jsonAPI from "@/api";

export const loadData = async function(
  uri,
  page,
  sort,
  dir = "desc",
  query = ""
) {
  return await loadWithPager(uri, page, sort, dir, query);
};

export const buildQueryString = function(
  segmentId = "",
  dateRange = "",
  tag = "",
  searchStr = ""
) {
  let query = {
    segment: segmentId,
    search: searchStr,
    range: dateRange,
    tag: tag,
  };
  query = Object.fromEntries(Object.entries(query).filter(([, v]) => v !== ""));
  const params = new URLSearchParams(query);
  return params.toString();
};

export const buildPaginationString = function(
  sort = "",
  dir = "",
  page = 1,
  contact_type = "",
  per_page = 10
) {
  let query = {
    sort: sort,
    dir: dir,
    page: page,
    contact_type: contact_type,
    per_page: per_page,
  };
  query = Object.fromEntries(Object.entries(query).filter(([, v]) => v !== ""));
  const params = new URLSearchParams(query);
  return params.toString();
};

export const loadWithPager = async function(
  uri,
  page,
  sort,
  dir = "desc",
  query = ""
) {
  let queryString = `page=${page}`;
  if (sort) {
    queryString += `&sort=${sort}&dir=${dir}`;
  }
  if (query) {
    queryString += `&${query}`;
  }
  return await loadFromApi(uri, queryString);
};

export const loadFromApi = async function(uri, query = "") {
  if (query) {
    uri += `?${query}`;
  }
  try {
    const response = await jsonAPI.get(uri);
    return response.data;
  } catch (error) {
    window.Bus.$emit("flash-message", {
      text: "Error fetching results",
      type: "error",
    });
    throw error;
  }
};

export const loadFromApiPlain = async function(uri, query = "") {
  if (query) {
    uri += `?${query}`;
  }
  const response = await jsonAPI.get(uri);
  return response.data;
};

export const postToApi = async function(uri, data = "", message = "") {
  try {
    const response = await jsonAPI.post(uri, data);
    if (message) {
      window.Bus.$emit("flash-message", {
        text: message,
        type: "success",
      });
    }
    return response.data;
  } catch (error) {
    window.Bus.$emit("flash-message", {
      text: "Error fetching results",
      type: "error",
    });
    throw error;
  }
};

export const updateApi = async function(uri, data, message = "") {
  try {
    const response = await jsonAPI.put(uri, data);
    if (message) {
      window.Bus.$emit("flash-message", {
        text: message,
        type: "success",
      });
    }
    return response.data;
  } catch (error) {
    window.Bus.$emit("flash-message", {
      text: "Error fetching results",
      type: "error",
    });
    throw error;
  }
};
